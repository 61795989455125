import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import styled, { css } from 'styled-components';
import Layout from '~/components/layout';
import SEO from '~/components/seo';
import TitleWithCatchCopy from '~/components/TitleWithCatchCopy';
import ServiceItem from '~/components/about/ServiceItem';
import {
  TopSection,
  Description,
  DescriptionWrapper,
  Section,
  TopTitleWrapper,
  PageTopThumbnailWrapper,
  StaticThumbnail,
  media,
  colors,
  commonKeyframes,
  easings,
} from '~/styles';
import animationSvg from '~/assets/svgs/animation.svg';
import modelingSvg from '~/assets/svgs/modeling.svg';
import motionCaptureSvg from '~/assets/svgs/motion-capture.svg';
import othersSvg from '~/assets/svgs/others.svg';
import pipelineSvg from '~/assets/svgs/pipeline.svg';
import virtualProductionSvg from '~/assets/svgs/virtual-production.svg';
import xrSvg from '~/assets/svgs/xr.svg';
import aboutThumbnail from '~/assets/images/about.jpg';
import { useIntersection } from '~/hooks';
import TextBreaks from '~/components/TextBreaks';

const services = [
  {
    titleKey: 'about.pipeline.title',
    textKey: 'about.pipeline.description',
    icon: pipelineSvg,
  },
  {
    titleKey: 'about.animation.title',
    textKey: 'about.animation.description',
    icon: animationSvg,
  },
  {
    titleKey: 'about.motionCapture.title',
    textKey: 'about.motionCapture.description',
    icon: motionCaptureSvg,
    link: {
      url: 'about.motionCapture.linkURL',
      label: 'about.motionCapture.linkLabel',
    },
  },
  {
    titleKey: 'about.modeling.title',
    textKey: 'about.modeling.description',
    icon: modelingSvg,
  },
  {
    titleKey: 'about.virtualProduction.title',
    textKey: 'about.virtualProduction.description',
    icon: virtualProductionSvg,
  },
  {
    titleKey: 'about.vr.title',
    textKey: 'about.vr.description',
    icon: xrSvg,
  },
  {
    titleKey: 'about.others.title',
    textKey: 'about.others.description',
    icon: othersSvg,
  },
];

const AboutPage: React.VFC<PageProps> = () => {
  const { t } = useTranslation();
  const [isDescriptionIntersection, descriptionIntersectionRef] =
    useIntersection();
  const [isServiceIntersection, serviceIntersectionRef] =
    useIntersection<HTMLDivElement>();
  const [isSummaryIntersection, summaryIntersectionRef] =
    useIntersection<HTMLDivElement>();
  return (
    <>
      <SEO title="About" />
      <Layout>
        <TopSection>
          <PageTopThumbnailWrapper>
            <StaticThumbnail src={aboutThumbnail} alt="thumbnail" />
          </PageTopThumbnailWrapper>
          <TopTitleWrapper>
            <TitleWithCatchCopy
              title="about.top.title"
              catchCopy="about.top.catchCopy"
              catchSub="about.top.catchSub"
              byKey
              top
              isIntersection
            />
          </TopTitleWrapper>
        </TopSection>
        <Section ref={descriptionIntersectionRef}>
          <DescriptionWrapper
            showAnim
            isIntersection={isDescriptionIntersection}
            delay={0.3}
          >
            <Description>{t('about.top.description')}</Description>
          </DescriptionWrapper>
        </Section>
        <Section ref={serviceIntersectionRef}>
          <TitleWithCatchCopy
            title="about.service.title"
            catchCopy="about.service.catchCopy"
            catchSub="about.service.catchSub"
            byKey
            isIntersection={isServiceIntersection}
          />
          <DescriptionWrapper showAnim isIntersection={isServiceIntersection}>
            <Description>{t('about.service.description')}</Description>
          </DescriptionWrapper>

          <ServiceList>
            {services.map((service) => (
              <ServiceItem
                title={t(service.titleKey)}
                text={t(service.textKey)}
                icon={service.icon}
                key={service.titleKey}
                link={
                  service.link
                    ? { label: t(service.link.label), url: t(service.link.url) }
                    : undefined
                }
                isIntersection={isServiceIntersection}
              />
            ))}
          </ServiceList>
        </Section>
        <Section>
          <TitleWithCatchCopy
            title="about.summary.title"
            catchCopy="about.summary.catchCopy"
            catchSub="about.summary.catchSub"
            byKey
            isIntersection={isSummaryIntersection}
            ref={summaryIntersectionRef}
          />
          <SummaryList isIntersection={isSummaryIntersection}>
            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.name.title')}</dt>
              <SummaryText>
                {t('about.summary.data.name.description')}
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.business.title')}</dt>
              <SummaryText>
                {t('about.summary.data.business.description')}
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.established.title')}</dt>
              <SummaryText>
                {t('about.summary.data.established.description')}
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.office.title')}</dt>
              <SummaryText>
                <Trans
                  i18nKey="about.summary.data.office.description"
                  components={[...TextBreaks]}
                />
                <wbr />
                <SummaryMapLinkButton
                  href="https://goo.gl/maps/3MxuH8fKSpgVwvcr8"
                  target="_blank"
                  rel="noreferrer"
                >
                  MAP
                </SummaryMapLinkButton>
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.studio.title')}</dt>
              <SummaryText>
                <Trans
                  i18nKey="about.summary.data.studio.description"
                  components={[...TextBreaks]}
                />
                <wbr />
                <SummaryMapLinkButton
                  href="https://goo.gl/maps/Vo9Bhxk5SEByawuW7"
                  target="_blank"
                  rel="noreferrer"
                >
                  MAP
                </SummaryMapLinkButton>
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.representative.title')}</dt>
              <SummaryText>
                {t('about.summary.data.representative.description')}
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />

            <SummaryItem>
              <dt>{t('about.summary.data.capital.title')}</dt>
              <SummaryText>
                {t('about.summary.data.capital.description')}
              </SummaryText>
            </SummaryItem>

            <SummaryDivider />
          </SummaryList>
        </Section>
      </Layout>
    </>
  );
};

const ServiceList = styled.ul`
  display: grid;
  margin: 0;

  ${media.mdUp} {
    grid-gap: 60px;
  }

  ${media.md} {
    grid-gap: 72px;
  }

  ${media.smDown} {
    grid-gap: 88px;
  }
`;

const SummaryItem = styled.div`
  display: contents;
  & > * {
    transform: translateY(3em);
    opacity: 0;
  }
`;

const SummaryDivider = styled.div`
  grid-column: 1 / span 2;
  height: 1px;
  background-color: ${colors.white};
  opacity: 0;

  ${media.smDown} {
    grid-column: 1 / span 1;
    margin: 12px 0;
  }
`;

const SummaryList = styled.dl<{ isIntersection: boolean }>`
  display: grid;
  margin: 0;
  grid-gap: 32px;
  grid-template-columns: auto 1fr;
  width: 100%;

  ${media.smDown} {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    text-align: center;
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      --delay-offset: 1s;

      /* SummaryDivider */
      /* MEMO: 0.15s * n + 0.07s ずつ */
      ${SummaryDivider} {
        animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic}
          forwards;

        &:nth-child(1) {
          --animation-delay: var(--delay-offset);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(3) {
          --animation-delay: calc(var(--delay-offset) + 0.22s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(5) {
          --animation-delay: calc(var(--delay-offset) + 0.37s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(7) {
          --animation-delay: calc(var(--delay-offset) + 0.52s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(9) {
          --animation-delay: calc(var(--delay-offset) + 0.67s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(11) {
          --animation-delay: calc(var(--delay-offset) + 0.82s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(13) {
          --animation-delay: calc(var(--delay-offset) + 0.97s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
        &:nth-child(15) {
          --animation-delay: calc(var(--delay-offset) + 1.12s);
          animation-delay: var(--animation-delay), var(--animation-delay);
        }
      }

      /* SummaryItem */
      /* MEMO: SummaryDivider を挟むので、0.15s ずつ */
      ${SummaryItem} {
        & > * {
          animation: ${commonKeyframes.fadeIn} 0.6s ${easings.easeOutCubic}
              forwards,
            ${commonKeyframes.slideIn} 0.6s ${easings.easeOutCubic} forwards;
        }

        &:nth-child(2) {
          & > * {
            --animation-delay: var(--delay-offset);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
        &:nth-child(4) {
          & > * {
            --animation-delay: calc(var(--delay-offset) + 0.15s);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
        &:nth-child(6) {
          & > * {
            --animation-delay: calc(var(--delay-offset) + 0.3s);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
        &:nth-child(8) {
          & > * {
            --animation-delay: calc(var(--delay-offset) + 0.45s);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
        &:nth-child(10) {
          & > * {
            --animation-delay: calc(var(--delay-offset) + 0.6s);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
        &:nth-child(12) {
          & > * {
            --animation-delay: calc(var(--delay-offset) + 0.75s);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
        &:nth-child(14) {
          & > * {
            --animation-delay: calc(var(--delay-offset) + 0.9s);
            animation-delay: var(--animation-delay), var(--animation-delay);
          }
        }
      }
    `}
`;

const SummaryText = styled.dd`
  line-height: 2;
  word-break: keep-all;
`;

const SummaryMapLinkButton = styled.a`
  display: inline-block;
  border: 1px solid ${colors.white};
  padding: 6px 6px 4px;
  margin-left: 8px;
  line-height: 0.8;
  cursor: pointer;
  transition: transform 0.3s ${easings.easeOutCubic};

  &:hover {
    transform: scale(0.95);
  }
`;

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
