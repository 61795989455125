import React from 'react';
import styled, { css } from 'styled-components';
import {
  SmallText,
  DescriptionWrapper,
  media,
  commonKeyframes,
  easings,
} from '~/styles';

interface ServiceItemProps {
  title: string;
  text: string;
  icon: string;
  isIntersection: boolean;
  link?: {
    label: string;
    url: string;
  };
}

const ServiceItem: React.VFC<ServiceItemProps> = ({
  title,
  text,
  icon,
  isIntersection,
  link,
}) => (
  <Wrapper isIntersection={isIntersection}>
    <ServiceHeader>
      <ServiceIcon src={icon} />
      <ServiceTitle>{title}</ServiceTitle>
    </ServiceHeader>
    <DescriptionWrapper>
      <SmallText>
        {text}
        {link && (
          <>
            &nbsp;&nbsp;
            <a href={link.url} target="_blank" rel="noreferrer">
              {link.label}
            </a>
          </>
        )}
      </SmallText>
    </DescriptionWrapper>
  </Wrapper>
);
const Wrapper = styled.li<{ isIntersection: boolean }>`
  display: grid;
  grid-gap: 32px;
  opacity: 0;
  transform: translateY(4em);
  --delay-offset: 1.45s;

  &:nth-child(1) {
    --animation-delay: var(--delay-offset);
    animation-delay: var(--animation-delay);
  }
  &:nth-child(2) {
    --animation-delay: calc(var(--delay-offset) + 0.15s);
    animation-delay: var(--animation-delay);
  }
  &:nth-child(3) {
    --animation-delay: calc(var(--delay-offset) + 0.3s);
    animation-delay: var(--animation-delay);
  }
  &:nth-child(4) {
    --animation-delay: calc(var(--delay-offset) + 0.45s);
    animation-delay: var(--animation-delay);
  }
  &:nth-child(5) {
    --animation-delay: calc(var(--delay-offset) + 0.6s);
    animation-delay: var(--animation-delay);
  }
  &:nth-child(6) {
    --animation-delay: calc(var(--delay-offset) + 0.75s);
    animation-delay: var(--animation-delay);
  }
  &:nth-child(7) {
    --animation-delay: calc(var(--delay-offset) + 0.9s);
    animation-delay: var(--animation-delay);
  }

  ${({ isIntersection }) =>
    isIntersection &&
    css`
      animation: ${commonKeyframes.fadeIn} ${easings.easeOutCubic} 1s forwards,
        ${commonKeyframes.slideIn} ${easings.easeOutCubic} 1s forwards;
    `}
`;

const ServiceHeader = styled.header`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 32px;
  align-items: center;

  ${media.mdDown} {
    grid-gap: 28px;
    grid-template-columns: auto;
    justify-items: center;
    text-align: center;
  }
`;

const ServiceIcon = styled.img`
  height: 118px;
  width: 118px;
`;

const ServiceTitle = styled.p`
  font-size: 24px;
  line-height: 1.6;
`;

export default ServiceItem;
